.Texx{
    text-align: center;
    padding: 10px;
    //color: #060e54;
    color: #000000;
    margin-top: 40px ;
    letter-spacing: 2px;
}
.par-text{
    align-items: center;
    text-align: center;
    color: #000000;
    letter-spacing: 2px;
    line-height: 1.5;
}
.containerbla{
    background: rgb(255, 255, 255);
    //background: linear-gradient(90deg, rgb(5, 4, 43) 0%, rgb(42, 21, 79) 48%, rgb(5, 4, 43) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.containeralb{
    width: 70%;
    justify-items: center;
    align-items: center;
    margin-left: 15%;
    margin-right: 15%;

}
.carousel-container{
    align-items: center;
    justify-items: center;
    width: 200vh;
}
.carousel-item{
    align-items: center;
    width: 50%;
    height: 50%;
    margin-left: 12%;
}
.caption-color{
    color: black;
    justify-items: center;
    align-items: center;
}
.info-image{
    width: 100%;
    border-radius: 50%;
}
.center-text {
    margin: auto;
    width: 40%;
    padding: 10px;
}

.card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}
.left-card{
    flex: 0 0 50%;
    margin:auto;
    text-align: center;
    margin-left: 5%;
}

.right-card{
    flex: 1;
    margin-left: 5%;
    text-align: center;
}

.contactform-card{
    flex: 0 0 50%;
    margin:auto;
    text-align: center;
    margin-left: 5%;
    background-color: aliceblue;
}

.contactaddress-card{
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: aliceblue;
}


.parent {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
}

.child {
    flex: 1 0 21%; /* explanation below */
    margin: 5px;
    height: 300px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px;
}



@media only screen and (max-width: 700px) {
    .center-text {
        border-style: solid;
        border-width: 10px;
        border-color: #ae98e9;
    }
    .Texx{

        padding: 10px;
        //color: #060e54;
        color: #000000;
        margin-top: 40px ;
        letter-spacing: 2px;
    }
    .par-text{


        color: #000000;
        letter-spacing: 2px;
        line-height: 1.5;
    }
    .card-container{

        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
    .left-card{

        margin:auto;

        margin-left: 5%;
    }

    .right-card{

        margin-left: 5%;

    }
    .info-image{
        width: 100%;
        border-radius: 5%;
    }
}



