
#testbutton {
    width: 150px;
    height:50px;
    border-radius:180px;
    position:relative;
    left:calc(50% - 75px);
    top:calc(50% - 25px);
    background: linear-gradient(60deg, #8533f7, #6637e3, #ef4eb9, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    cursor:pointer;
    line-height:12px;
}

#testbutton:before {
    content:'';
    z-index:1;
    position:absolute;
    display:block;
    width:80%;
    height:70%;
    top:15%;
    left:10%;
    transition: 0.3s opacity ease-in-out;
    filter:blur(15px);
    opacity:0;
    background: linear-gradient(60deg, #8533f7, #6637e3, #ef4eb9, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
}

#testbutton:hover:before {
    opacity:1;
    transition: 0.3s opacity ease-in-out;
    filter:blur(25px);
    background: linear-gradient(60deg, #7e33f7, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
}

#testbutton:after {
    content:'İletişim';
    text-align:center;
    line-height:40px;
    font-size:18px;
    color:rgba(235,235,235,1);
    z-index:5;
    position:absolute;
    display:block;
    border-radius:180px;
    width:92%;
    height:80%;
    top:10%;
    left:4%;
    background-color: #120928;
}
a.fill-div {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
}
