
.center {
    margin: auto;
    display: flex;
    align-items: center;
    justify-items: center;
    background: #120928;

}
.right {
    float: right;
    width: 50%;
    margin-left: 12%;
    padding: 10px;
}

.links{
    margin-left: 40%;
    color: darkmagenta;
    float: left;
}

