.info-image{
    width: 100%;
}

.info-image-right{
    width: 120%;

}

.center-text {
    margin: auto;
    width: 40%;
    padding: 10px;
    border-style: solid;
    border-width: 10px;
    border-color: #ae98e9;
}

.card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}
.left-card{
    flex: 0 0 50%;
    margin:auto;
    text-align: center;
    margin-left: 5%;
}

.right-card{
    flex: 1;
    margin-left: 5%;
    text-align: center;
}
.carousel-top-rec{
    width: 100%;
    height: 50px;
    background: #120928;
}
.carousel-top-rec2{
    width: 100%;
    height: 50px;
    background: #5e4798;
}
.carousel-top-rec3{
    width: 100%;
    height: 50px;
    background: #ae98e9;
}

.carousel-container {
    position: relative;
}

/* Bottom right text */
.text-block {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: black;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (max-width: 700px) {
    .center-text {
        margin: auto;
        width: 100%;
        padding: 10px;
        border-style: solid;
        border-width: 10px;
        border-color: #ae98e9;
    }
    .card-container{
        display: block;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        border-color: #ae98e9;
        border-style: solid;
        border-width: 10px;
    }
    .left-card{
        margin:auto;
        text-align: center;

    }

    .right-card{
        margin-right: 5%;
        text-align: center;

    }
    .info-image{
        width: 100%;
    }
}
