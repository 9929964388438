.Mobile-header{
    width: 100%;
    background: #120928;
}

.Mobile-logo{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.Logos{
    position: absolute;
    left: 50%;
    margin: -25px 0 0 -25px;
}
