.navbar-t {
    background-color: #120928;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
}

/* Style the links inside the navigation bar */
.navbar-t a {
    display: block;
    color: #f2f2f2;
    text-align: center;
    margin-top: 10px;
    text-decoration: none;
    font-size: 8px;
}
.navbar-t ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}

.navbar-t ul li {
    list-style: none;
    flex: 0 0 25%;
    margin-top: 2px;
}
