.card-text{
    margin-top: 10px;
    color: #000000;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.icon-cards{
    margin:auto;
    margin-left: 10%;
    margin-right: 10%;
    justify-items: center;
    align-items: center;
    margin-top: 120px;
}
.icon-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
@media only screen and (max-width: 700px) {
    .icon-cards{
        margin:auto;
        margin-top: 10px;
        margin-left: 1%;
        margin-right: 1%;
    }

}
