.column-about {
    float: left;
    width: 50%;
    background: #ae98e9;
}

/* Clear floats after the columns */
.row-about:after {
    content: "";
    display: table;
    clear: both;
    background: #ae98e9;
}

.about-text {
    margin-left: 2%;
    margin-right: 2%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
}
.about-text2 {
    margin-left: 2%;
    margin-right: 2%;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
}

@media only screen and (max-width: 900px) {

}
